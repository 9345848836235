import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_ENTITY_TYPE_URL } from '../../connection.data';

@Injectable()
export class EntityTypeService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_ENTITY_TYPE_URL;
    }
}